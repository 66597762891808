import React, {useState, useRef} from "react";
import Navbar from "../components/Navbar";
import JobList from "../components/JobList";
import {graphql, Link} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import Head from "../components/Head";

import axios from 'axios';
import {GatsbyImage} from "gatsby-plugin-image";
import ReCAPTCHA from "react-google-recaptcha";

export const query = graphql`
    {
  allSanitySettings {
    edges {
      node {    
        fblink
        instalink
        kontaktemail
        telefon
        standort{
                title
                addresszeile1
        addresszeile2
        addresszeile3
        telefon
            zeiten
        }
      }
    }
  }
}
`


const Kontakt = ({data}) => {

    const recaptchaRef = useRef(null);  // Ref for the reCAPTCHA component

    const [valueKontakt, setValueKontakt] = React.useState({})
    //const [serverResponse, setServerResponse] = React.useState(``)

    let serverResponse = useRef();

    const kontakt = data.allSanitySettings.edges[0].node;

    function handleChange(e) {
        valueKontakt[e.target.id] = e.target.value
        serverResponse.current.style.color = "#000000";
        serverResponse.current.innerText = "";
        setValueKontakt({ ...valueKontakt })
        console.log(e);
    }


    async function onSubmitKontakt(e) {
        e.preventDefault()
        if(valueKontakt.kontaktname && valueKontakt.kontaktemail && valueKontakt.kontaktstrasse && valueKontakt.kontaktnr && valueKontakt.kontaktwohnort && valueKontakt.kontaktplz && valueKontakt.kontakttelefonnummer && document.querySelector('#kontaktnachricht').innerText !== ""  && valueKontakt?.captcha){

            valueKontakt.kontaktnachricht = document.querySelector('#kontaktnachricht').innerText;

            console.log(valueKontakt.kontaktname);

            let anschrift = valueKontakt.kontaktstrasse + " " + valueKontakt.kontaktnr + ", " + valueKontakt.kontaktplz + " " + valueKontakt.kontaktwohnort;

            var params = new URLSearchParams();
            params.append('name', valueKontakt.kontaktname);
            params.append('email', valueKontakt.kontaktemail);
            params.append('anschrift', anschrift);
            params.append('tel', valueKontakt.kontakttelefonnummer);
            params.append('nachricht', valueKontakt.kontaktnachricht);
            params.append('captcha', valueKontakt.captcha);

            axios.post('https://api.eniosol.de/form.php', params)
                .then(function (response) {
                    serverResponse.current.style.color = "#64C028";
                    serverResponse.current.innerText = "Nachricht wurde abgeschickt!";
                    setValueKontakt({});
                    recaptchaRef.current.reset();
                    document.querySelector('#kontaktnachricht').innerText = "";
                    console.log(response);
                })
                .catch(function (error) {
                    serverResponse.current.style.color = "#F05353";
                    serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht. Bitte versuche es erneut.";
                    console.log(error);
                });

            /*axios({
                method: 'post',
                url: `/api/form.php`,
                params

            })
                .then(response => {
                    serverResponse.current.style.color = "#64C028";
                    serverResponse.current.innerText = "Nachricht wurde abgeschickt!";
                    console.log(response);
                })
                .catch(error => {
                    serverResponse.current.style.color = "#F05353";
                    serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht. Bitte versuche es erneut.";
                    console.log(error);
                });

            /*
            kontaktName: valueKonakt.kontaktname,
                    kontaktemail: valueKonakt.kontaktemail,
                    kontaktanschrift: valueKonakt.kontaktanschrift,
                    kontakttelefonnummer: valueKonakt.kontakttelefonnummer,
                    kontaktnachricht: valueKonakt.kontaktnachricht,
             */

            /*axios.post('/api/form.php', {
                firstName: 'Fred',
                lastName: 'Flintstone'
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });*/
        }
        else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";
        }
    }

    let tel = kontakt.telefon.replace('—', '');

    function onChange(value) {
        console.log("Captcha value:", value);
        setValueKontakt(prev => ({...prev, captcha: value}));
    }

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}>
            <Head title="Kontakt"/>
            <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                <div className="marqueeText color-orange">
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                    <span className="marqueeText__inner">KONTAKT</span>
                </div>
            </Marquee>
            <div className="kontaktpage">
                <div className="kontakt__inner">
                    <div className="kontakt__left">
                        <div className="kontakt__btns">
                            <a href={"tel:" + tel} target="_blank" className="btn round kontaktbtn outline black smalltext bold hover-moveup first">ANRUFEN</a>
                            <a href={kontakt.fblink} target="_blank" className="btn round kontaktbtn filled black smalltext bold hover-moveup">FACEBOOK</a>
                            <a href={kontakt.instalink} target="_blank" className="btn round kontaktbtn filled orange smalltext bold hover-moveup last">INSTAGRAM</a>
                        </div>
                        {kontakt.standort.map(el => (
                            <div className="kontakt__text">
                                <p className="large color-orange">{el.title}:</p>
                                <p className="large">{el.addresszeile1}</p>
                                <p className="large">{el.addresszeile2}</p>
                                <p className="large">{el.addresszeile3}</p>
                                {el.zeiten ? <><br/>
                                    <br/>
                                    <p className="large">{el.zeiten}</p></> : <></> }
                                <br/>
                                <br/>
                                <p className="large">T: {el.telefon}</p>
                            </div>
                        ))}


                    </div>
                    <div className="kontakt__right">
                        <form onSubmit={onSubmitKontakt} className="kontakt__form" method="post" action="/api/form.php">
                            <label className="kontakt__label">
                                <p className="small bold">Name*</p>
                                <input type="text" name="kontaktname" className="kontakt__inputfield" id="kontaktname" value={valueKontakt[`kontaktname`] || ``} onChange={handleChange}/>
                            </label>
                            <label className="kontakt__label anschrift__label">
                                <p className="small bold wordspacing">
                                    <span className="desktop">Anschrift*</span>
                                </p>
                                <div className={"kontakt__inputfieldcontainer"}>
                                    <input type="text" name="kontaktstrasse" id="kontaktstrasse" className="kontakt__inputfield light-placeholder" placeholder="STRASSE" value={valueKontakt[`kontaktstrasse`] || ``} onChange={handleChange}/>
                                    <input type="text" name="kontaktnr" id="kontaktnr" className="kontakt__inputfield light-placeholder" placeholder="NR" value={valueKontakt[`kontaktnr`] || ``} onChange={handleChange}/>
                                </div>
                            </label>
                            <label className="kontakt__label">
                                <p className="small bold wordspacing">
                                    <span className="mobile">Anschrift*</span></p>
                                <div className={"kontakt__inputfieldcontainer"}>
                                    <input type="text" name="kontaktplz" id="kontaktplz" className="kontakt__inputfield light-placeholder" placeholder="PLZ" value={valueKontakt[`kontaktplz`] || ``} onChange={handleChange}/>
                                    <input type="text" name="kontaktwohnort" id="kontaktwohnort" className="kontakt__inputfield light-placeholder" placeholder="WOHNORT" value={valueKontakt[`kontaktwohnort`] || ``} onChange={handleChange}/>
                                </div>
                            </label>
                            <label className="kontakt__label">
                                <p className="small bold">Telefonnummer*</p>
                                <input type="tel" name="kontakttelefonnummer" className="kontakt__inputfield" id="kontakttelefonnummer" value={valueKontakt[`kontakttelefonnummer`] || ``} onChange={handleChange}/>
                            </label>
                            <label className="kontakt__label">
                                <p className="small bold">E-Mail*</p>
                                <input type="email" name="kontaktemail" className="kontakt__inputfield" id="kontaktemail" value={valueKontakt[`kontaktemail`] || ``} onChange={handleChange}/>
                            </label>
                            <label className="kontakt__label">
                                <p className="small bold">Nachricht*</p>
                                <span className="textarea kontakt__inputfield" id="kontaktnachricht" role="textbox" contentEditable></span>
                            </label>
                            <div className="kontakt__btn__container mt-4 mb-4">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6Ldwp9opAAAAAPAsgGJyL1lQJ25b4KQ6TXHJv4lE"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="kontakt__btn__container">
                                <p className="small bold serverresponse !ml-0" ref={serverResponse}></p>
                            </div>
                            <div class="kontakt__btn__container">
                                <button type="submit" value="JETZT SENDEN" className="btn smalltext bold outline black large mobile-large color-black">JETZT SENDEN</button>
                                <p className="small bold">*Pflichtfelder</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Kontakt;


/*
                                <input type="textarea" name="nachricht" className="kontakt__inputfield" id="nachricht" value={value[`nachricht`] || ``} onChange={handleChange}/>

 */

/*const postToAPI = data => fetch(`/api/form.php`, {
        method: 'POST',
        body: {
            firstName: 'Fred',
            lastName: 'Feuerstein'
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(r => {
        console.log(r);
    });*/
